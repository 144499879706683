import './App.scss';

function App() {
  return (
    <div id="neon84animation">
        <h2 id="bTitle">Berserk.cl</h2>
        <div id="bLogo">
          <img src="img/logo.svg" className="letmeglow" alt="Berserk.cl bear logo" />
        </div>
    </div>
  );
}

export default App;
